import { Injectable } from '@angular/core';
import { BannerModel } from './core';
import { createStore, select, withProps } from '@ngneat/elf';

interface AppStateProps {
  banner: BannerModel;
  showBanner: boolean;
  isSiderCollapsed: boolean;
  isMobile: boolean;
}

const store = createStore(
  {
    name: 'app',
  },
  withProps<AppStateProps>({
    banner: { message: '' },
    showBanner: false,
    isSiderCollapsed: false,
    isMobile: false,
  }),
);

Injectable({ providedIn: 'root' });
export class AppRepository {
  isMobile$ = store.pipe(select((state) => state.isMobile));
  banner$ = store.pipe(select((state) => state.banner));
  isSiderCollapsed$ = store.pipe(select((state) => state.isSiderCollapsed));
  showBanner$ = store.pipe(select((state) => state.showBanner));

  setBanner(banner: BannerModel) {
    store.update((state) => ({ ...state, banner }));
  }

  clearBanner() {
    store.update((state) => ({ ...state, banner: { message: '' } }));
  }

  toggleSider(toggleState?: boolean) {
    if (toggleState !== undefined) {
      store.update((state) => ({ ...state, isSiderCollapsed: toggleState }));
    } else {
      store.update((state) => ({ ...state, isSiderCollapsed: !state.isSiderCollapsed }));
    }
  }

  setIsMobile(isMobile: boolean) {
    store.update((state) => ({ ...state, isMobile }));
  }

  get isMobile() {
    return store.state.isMobile;
  }

  get isSiderCollapsed() {
    return store.state.isSiderCollapsed;
  }

  get banner() {
    return store.state.banner;
  }
}
