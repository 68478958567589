import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

// Services
import { AuthService } from '../services/auth.service';

/**
 * Guard that preloads the admin module by checking if the user has an active session.
 */
@Injectable({
  providedIn: 'root',
})
export class PreloadAdminGuard {
  constructor(
    private authService: AuthService,
  ) {}

  /**
   * Get the user's session, store the token in the auth service and check if the user can activate the route.
   * @returns An Observable, Promise, or boolean indicating if the user can activate the route.
   */
  canActivate(): Observable<boolean> {
    return this.authService.getSession().pipe(
      map((session) => {
        if (session) {
          this.authService.initializeService(session.token);
          return true;
        } else {
          return false;
        }
      }),
    );
  }
}
