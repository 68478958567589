import { Component } from '@angular/core';

// UI Modules
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@Component({
    standalone: true,
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.less'],
    imports: [NzSpinModule, NzProgressModule, NzLayoutModule],
})
export class PageLoaderComponent {
    loadingImg = './assets/logo-full.png';
}
