export const environment = {
  production: false,
  googleApiKey: 'AIzaSyCrTVnvt996MFPXLS-HEA6r1JZCbNLe5UM',
  api: {
    serverUrl: 'https://api-develop.property.guestgenie.com/api/v1',
  },
  firebase: {
    apiKey: 'AIzaSyC9fgPCtMPezUBNjKUAIcyEGoCI8q0mhS0',
    authDomain: 'guestgenie-backoffice-develop.firebaseapp.com',
    projectId: 'guestgenie-backoffice-develop',
    storageBucket: 'guestgenie-backoffice-develop.appspot.com',
    messagingSenderId: '2058910583',
    appId: '1:2058910583:web:0f5f422d8d6059b0e6ff72',
  },
  recaptchaSiteKey: '6Ld9SNkpAAAAACSnjimDtcdwCTX6saFuBf2XJK32',
  guestGenieAppUrl: 'https://develop.app.guestgenie.com/',
  intercomWorkspace: 'kh5l45bz',
};
