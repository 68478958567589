import { Component, inject } from '@angular/core';
import { Subscription, fromEvent, map, merge, of } from 'rxjs';

// Store
import { AppRepository } from './app.repository';

/**
 * The root component of the application.
 */
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  /**
   * Subscription for network status changes.
   */
  networkStatus$: Subscription = Subscription.EMPTY;

  /**
   * The application store.
   */
  readonly appRepository = inject(AppRepository);

  constructor() {}

  /**
   * Lifecycle hook that is called after the component is initialized.
   * It checks the network status.
   */
  ngOnInit(): void {
    this.checkNetworkStatus();

    /* this.user$.subscribe((user) => {
      if (user) {
        console.log(user);
        (<any>window).Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "qkh88dfd",
          name: user.name, // Full name
          email: user.email, // the email for your user
          updated_at: user.updated_at, // Last update of the user
        });
      }
    }); */
  }

  /**
   * Checks the network status and updates the application store banner accordingly.
   */
  checkNetworkStatus() {
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        if (status) {
          this.appRepository.clearBanner();
        } else {
          this.appRepository.setBanner({ message: 'No internet connection' });
        }
      });
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It unsubscribes from the network status subscription.
   */
  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
}
