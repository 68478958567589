import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import {
  Intercom as IntercomWeb,
  show,
  showArticle,
  showNewMessage,
  showSpace,
  shutdown,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private _http: HttpClient) {}

  initialize(user: any) {
    this.getIntercomIdentity().subscribe((identity) => {
      IntercomWeb({
        app_id: environment.intercomWorkspace,
        name: user?.displayName ? user?.displayName : '', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: identity.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        user_hash: identity.hash,
        signed_up_at: user?.metadata.creationTime
          ? Math.floor(new Date(user.metadata.creationTime).getTime() / 1000)
          : undefined,
        created_at: user?.metadata.creationTime
          ? Math.floor(new Date(user.metadata.creationTime).getTime() / 1000)
          : undefined,
          app_name: 'providers',
      });
    });
  }

  async helpCenter() {
    showSpace('help');
  }

  newMessage() {
    showNewMessage('');
  }

  displayMessenger() {
    show();
  }

  logout() {
    shutdown();
  }

  updateUser(user: { email?: string; name?: string }) {
    update(user);
  }

  trackEvent(eventName: string, metaData?: any) {
    trackEvent(eventName, metaData);
  }

  private getIntercomIdentity(): Observable<{ email: string; hash: string }> {
    return this._http.get<{ email: string; hash: string }>(`${environment.api.serverUrl}/intercom/identity-hash`);
  }

  shutdown() {
    shutdown();
  }

  showArticle(articleId: string) {
    showArticle(articleId);
  }

  reportAnIssue() {
    trackEvent('report-a-problem');
  }
}
