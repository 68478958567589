<!-- <div class="page-layout" *ngIf="isAuth0Loading$ | async; else auth0Loaded">
  <app-page-loader></app-page-loader>
</div>
<ng-template #auth0Loaded>
  <ngx-loading-bar [includeSpinner]="false" height="3px" color="#7642FF"></ngx-loading-bar>
  <router-outlet></router-outlet>
</ng-template>
 -->
<ngx-loading-bar [includeSpinner]="false" height="3px" color="#000" [includeSpinner]="true"></ngx-loading-bar>
<router-outlet></router-outlet>
