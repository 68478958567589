import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NzProgressModule } from 'ng-zorro-antd/progress';

@Component({
  selector: 'app-sending-request-loader',
  standalone: true,
  imports: [CommonModule, NzProgressModule],
  templateUrl: './sending-request-loader.component.html',
  styleUrl: './sending-request-loader.component.less'
})
export class SendingRequestLoaderComponent {
  @Input() public isLoading: boolean = false;
  @Input() public percentage: string = "0";
}
