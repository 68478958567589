import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

// Services
import { NzNotificationService } from 'ng-zorro-antd/notification';

/**
 * Interceptor that handles HTTP errors and displays notifications.
 */
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NzNotificationService) {}

  /**
   * Intercepts the HTTP request and handles any errors that occur.
   * @param request - The HTTP request.
   * @param next - The next HTTP handler in the chain.
   * @returns An observable of the HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.notificationService.error('Error', errorMsg);
        console.log(errorMsg);
        return throwError(() => new Error(errorMsg));
      }),
    );
  }
}
