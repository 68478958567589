import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { UserRoleModel } from '../models';

interface AuthProps {
  roles: UserRoleModel[];
  organizationRoles: UserRoleModel[];
  propertyRoles: UserRoleModel[];
  rootRoles: UserRoleModel[];
  loading: boolean;
  error: any;
}

const store = createStore(
  { name: 'auth' },
  withProps<AuthProps>({
    loading: false,
    error: null,
    organizationRoles: [],
    propertyRoles: [],
    rootRoles: [],
    roles: [],
  }),
);

Injectable({ providedIn: 'root' });
export class AuthRepository {
  constructor() {}

  organizationRoles$ = store.pipe(select((state) => state.organizationRoles));
  propertyRoles$ = store.pipe(select((state) => state.propertyRoles));
  rootRoles$ = store.pipe(select((state) => state.rootRoles));
  roles$ = store.pipe(select((state) => state.roles));
  isLoading$ = store.pipe(select((state) => state.loading));
  error$ = store.pipe(select((state) => state.error));

  setRoles(roles: UserRoleModel[], resourceType: string) {
    switch (resourceType) {
      case 'organization':
        this.setOrganizationRoles(roles);
        break;
      case 'property':
        this.setPropertyRoles(roles);
        break;
      case 'root':
        this.setRootRoles(roles);
        break;
    }
  }

  setOrganizationRoles(organizationRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, organizationRoles };
    });
  }

  setPropertyRoles(propertyRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, propertyRoles };
    });
  }

  setRootRoles(rootRoles: UserRoleModel[]) {
    store.update((state) => {
      return { ...state, rootRoles };
    });
  }

  setLoading(loading: boolean) {
    store.update((state) => {
      return { ...state, loading };
    });
  }

  setError(error: any) {
    store.update((state) => {
      return { ...state, error };
    });
  }

  getRoles(resourceType: 'organization' | 'property' | 'root' = 'root'): UserRoleModel[] {
    return store.getValue()[`${resourceType}Roles`];
  }
}
